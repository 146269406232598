<template>
  <!-- 北美CS留学求职规划班 -->
  <div class="vip-page">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">北美CS留学求职-全流程解析攻略</div>
            <div class="sch-desc">
				超全北美CS留学求职指南，时间规划路径方案事无巨细 <br />一网打尽你在北美留学求职道路上的所有问题，成功留美
            </div>
            <ul class="leet-date leet-sm-space">
<!--              <li>-->
<!--                <div class="val">7 <span>人</span></div>-->
<!--                <div class="txt">VIP小班</div>-->
<!--              </li>-->
              <li>
                <div class="val">5 <span>大板块</span></div>
                <div class="txt">课程内容</div>
              </li>
              <li>
                <div class="val">60 <span>节</span></div>
                <div class="txt">课程小节</div>
              </li>
              <li>
                <div class="val"><span>随时畅听</span></div>
                <div class="txt">录播形式</div>
              </li>
              <li>
                <div class="val">3 <span>年</span></div>
                <div class="txt">有效期</div>
              </li>
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
<!--              <img src="../../assets/images/scb/shipin.png" alt="" />-->
              <img src="../../assets/images/12-CsJob/shipin-Edward.png" alt="" />
              <div class="v-icon"></div>
<!--              <div class="v-title">课程简介 & 免费试听</div>-->
            </div>
            <!--            <div class="countDownText" v-if="countDownTimeStamp != -1">-->
            <!--              <span class="text"-->
            <!--                >下一期开课：<span class="countsNum">{{ countDays }}</span-->
            <!--                >天<span class="countsNum">{{ countHours }}</span-->
            <!--                >小时<span class="countsNum">{{ countMinutes }}</span-->
            <!--                >分<span class="countsNum">{{ countSeconds }}</span-->
            <!--                >秒</span-->
            <!--              >-->
            <!--            </div>-->
          </div>
        </div>
        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>
        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
<!--              <div-->
<!--                style="-->
<!--                  color: rgba(0, 0, 0, 0.65);-->
<!--                  text-align: center;-->
<!--                  font-size: 12px;-->
<!--                  position: absolute;-->
<!--                  right: 82px;-->
<!--                  top: 6px;-->
<!--                "-->
<!--              >-->
<!--                立即报名，立即开始！报名即学，不浪费一分一秒！-->
<!--              </div>-->
              <img src="@/assets/imgs/priceBanner.png" alt="" />
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div class="prices" v-if="comboData.length > 0">
                    <span style="font-size: 28px; color: #de1306">
<!--                      ${{-->
<!--                        comboData[0].usddiscountPrice != 0-->
<!--                          ? comboData[0].usddiscountPrice-->
<!--                          : comboData[0].usdprice-->
<!--                      }}-->
<!--                      ${{comboData[0].usdprice}}-->
                      &yen;{{comboData[0].price}}
                    </span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;
<!--                      /&nbsp;&yen;{{-->
<!--                        comboData[0].discountPrice != 0-->
<!--                          ? comboData[0].discountPrice-->
<!--                          : comboData[0].price-->
<!--                      }}-->
<!--                      / &yen;50000-->
<!--                      / &yen;{{comboData[0].price}}-->
                    </span
                    >
                  </div>
                  <div class="pricesTips">
                    <div class="dollar">支持美元付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div>
                </div>

                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>

        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
                :current-index="currentIndex"
              />
              <div v-if="stickyToggle" class="price" style="right: 75px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="comboData.length > 0"
                >
                  ${{comboData[0].usdprice }}
                </span>
                <span style="font-size: 12px; color: #e02020"
                  >
<!--                  /&yen;50000-->
                  /&yen;{{comboData[0].price }}
                </span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
		
		<div class="g-section">
			<div class="g-header">
				<h3>这门课适合谁？北美CS求职必修课！</h3>
				<p>还在为北美CS求职摸不着头脑，这门课一定要看</p>
			</div>
			<div class="container">
				<ul class="mast-list u-flex">
					<li>
						<div class="mast-card hoverUp">
							<div class="text-primary">01</div>
							<div class="tt">准备留美学CS的同学</div>
							<div class="desc">打算去美国读CS，不知如何选校，规划留美路径<br> 已经拿到留学offer，不知道接下来该做什么</div>
						</div>
					</li>
					<li>
						<div class="mast-card hoverUp">
							<div class="text-primary">02</div>
							<div class="tt">已经在美国读CS的同学</div>
							<div class="desc">刚去留学，一切都很茫然，不知道如何规划 <br>想要求职留美，不知从何做起，时间路径不清晰<br> 临近毕业，求职受挫，不知如何改进</div>
						</div>
					</li>
					<li>
						<div class="mast-card hoverUp">
							<div class="text-primary">03</div>
							<div class="tt">工作多年想肉身翻墙的职场人</div>
							<div class="desc">网上信息鱼龙混杂，信息碎片化，一头雾水 <br>想要寻找高效系统可行性肉身翻墙方案</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="g-section through-section">
			<div class="g-header">
				<h3>从留学到留美，打通所有卡点，小白也能轻松搞定</h3>
				<p>超全北美CS留学求职留美指南，路径规划各种疑问一网打尽</p>
			</div>
			<div class="container">
				<div class="through-main">
					<div class="through-row u-flex">
						<div class="col through-first">
							<div class="through-items">
								<div class="col">
									<div class="through-button through-ht1">北美CS留学</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .3);">本科</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .4);">研究生</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .5);">博士</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .6);">在职</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .7);">转码</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .3);">选校</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .5);">成本</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(29, 188, 196, .8);">学制</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button " style="background-color: rgba(29, 188, 196, 1);">以北美求职角度看待留学</div>
								</div>
							</div>
						</div>
						<div class="col through-second">
							<div class="through-items">
								<div class="col">
									<div class="through-button through-ht2">北美CS规划</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .3);">本科</div>
								</div><div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .5);">研究生</div>
								</div><div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .8);">博士</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .3);">面试内容</div>
								</div><div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .5);">找工形式</div>
								</div><div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .8);">刷题</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .5);">国内准备</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(39, 128, 190, .8);">面试区别</div>
								</div>
							</div>
						</div>
						<div class="col through-third">
							<div class="through-items">
								<div class="col">
									<div class="through-button through-ht3">北美CS求职</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .3);">OA</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .4);">Phone  Interview</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .5);">VO</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .8);">Onsite</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button">北美公司求职内部模块</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .3);">薪资</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .4);">实习</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .5);">应届</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(31, 89, 198, .8);">跳槽</div>
								</div>
							</div>
						</div>
						<div class="col through-forth">
							<div class="through-items">
								<div class="col">
									<div class="through-button through-ht4">身份转变 & 职场夜话</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .3);">F1</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .4);">CPT</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .5);">OPT</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .6);">H1B</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .8);">绿卡</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .3);">美国<br>工作</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .4);">晋升</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .5);">35危机</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .6);">物价等</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .8);">找对象</div>
								</div>
							</div>
							<div class="through-items">
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .4);">国内工作</div>
								</div>
								<div class="col">
									<div class="through-button" style="background-color: rgba(32, 35, 130, .8);">创业维艰</div>
								</div>
							</div>
						</div>
					</div>
					<div class="through-foot">
						北美CS留学求职全流程详细梳理，轻松搞定留学+求职+留美
					</div>
				</div>
			</div>
		</div>
		<div class="g-section">
			<div class="g-header">
				<h3>五大板块，打通北美CS留学求职的任督二脉</h3>
				<p>想要留学求职留美无从下手，手把手教你打通全部卡点</p>
			</div>
			<div class="container">
				<div class="need-cells">
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/12-CsJob/block1.png" alt="" /></div>
							<div class="flex-col">
								<h3>北美CS留学</h3>
								<p>
									手把手教你留学申请以及选校，帮你盘算留学成本，以及避开留学机构的坑
								</p>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/12-CsJob/block2.png" alt="" /></div>
							<div class="flex-col">
								<h3>北美CS规划</h3>
								<p>美本硕博规划直接上手，实操性强，北美找工、刷题、面试等疑问一网打尽</p>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/12-CsJob/block3.png" alt="" /></div>
							<div class="flex-col">
								<h3>职业夜话</h3>
								<p>十年资深从业者独家分享留美、回国、创业心路历程</p>
							</div>
						</div>
					</div>
				</div>
				<div class="need-cells" style="padding: 0 16.66%;margin-top: 30px;">
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/12-CsJob/block4.png" alt="" /></div>
							<div class="flex-col">
								<h3>北美CS求职</h3>
								<p>
									北美大厂面试流程，逐一详解，理清美国公司内幕、薪资等，求职准备快人一步，高效上岸大厂
								</p>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/12-CsJob/block5.png" alt="" /></div>
							<div class="flex-col">
								<h3>身份转变</h3>
								<p>扫除留美必经之路的各个卡点，为顺利留美做好万全准备</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="g-section" style="background-color: #F7F8F8;">
			<div class="g-header">
				<h3>课程特色</h3>
			</div>
			<div class="container">
				<ul class="ts-cells u-flex">
					<li>
						<div class="ts-card hoverUp">
							<div class="ts-card-hd">十年资深从业者授课</div>
							<div class="ts-card-bd">
								十年经验倾囊相授， 帮你扫除留学求职路上的所有坑， 最高效的留学求职方案， 助力成功留美。  
							</div>
						</div>
					</li>
					<li>
						<div class="ts-card hoverUp">
							<div class="ts-card-hd">多课时精品课程</div>
							<div class="ts-card-bd">
								五大板块，每个板块细分多个小节，事无巨细讲解梳理北美CS留学求职，可以直接上手的超全指南。
							</div>
						</div>
					</li>
					<li>
						<div class="ts-card hoverUp">
							<div class="ts-card-hd">课程随时观看</div>
							<div class="ts-card-bd">
								录播课程，学习时间灵活，不受时间地点限制，随看随学，可以反复学习消化。
							</div>
						</div>
					</li>
					<li>
						<div class="ts-card hoverUp">
							<div class="ts-card-hd">课程答疑解惑</div>
							<div class="ts-card-bd">
								评论区留言提问，老师耐心解答，帮你扫除学习路上的拦路虎，高效吸收。
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="g-section">
			<div class="g-header">
				<h3>超强师资，精心打磨求职课程</h3>
			</div>
			<div class="container" style="padding-top: 30px;">
				<div class="metic-card hoverUp">
					<div class="metic-hd">Edward Shi</div>
					<div class="metic-bd">
						<div class="t1">CSON职场教育品牌创始人</div>
						<div class="info">
							●   十年北美CS留学求职从业经验 <br>
							●   面试人数超1000人 <br>
							●   帮助超多学生拿到心仪offer<br>
						</div>
					</div>
				</div>
			</div>
		</div>
		<addBanner title="北美CS留学求职全方位讲解梳理，打通各个卡点" desc="帮你用最短的时间获取最有效的信息，北美CS留学求职一网打尽" :imgs="img2" btnClass="btn2"></addBanner>
          <div class="g-section leet-jj-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                      class="gg-card"
                      style="margin: 0 auto !important"
                      v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="comboData.length > 1 ? 12 : 24">
                        <div class="gg-yh-cell">
                          <div
                              :class="[
                              comboData.length > 1
                                ? 'gg-yh-grid red'
                                : 'gg-yh-grid',
                            ]"
                          >
<!--                            <div-->
<!--                                class="gg-tag-discountTip"-->
<!--                                v-if="comboData[0].discountPrice > 0"-->
<!--                            >-->
<!--                              <img src="@/assets/imgs/discountTip.png" alt="" />-->
<!--                            </div>-->
                            <div class="gg-price">
<!--                              ${{-->
<!--                                comboData[0].usddiscountPrice > 0-->
<!--                                    ? comboData[0].usdprice : comboData[0].usddiscountPrice-->
<!--                              }}-->
                              ${{comboData[0].usdprice}}
                            </div>
                            <div class="gg-y-price">
<!--                              ¥{{-->
<!--                                comboData[0].discountPrice > 0-->
<!--                                    ? comboData[0].price : comboData[0].discountPrice-->
<!--                              }}-->
<!--                              ¥50000-->
                              ¥{{comboData[0].price}}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
<!--                              立即报名，立即开始，前置课程提前学习<br />不浪费一分一秒，报名立刻学习-->
							  立即报名，即刻学习，录播模式 <br />不浪费一分一秒，高效上岸
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >3年有效期
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >北美CS留学求职超全指南
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >录播+答疑，有学有答，学习更高效
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >支持支付宝/微信等多种支付方式
                              </li>
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                  type="buy"
                                  @click="buyCombo"
                                  :class="[comboData.length > 1 ? 'red' : '']"
                              >立即购买
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="7"></presales-consultation>
        </div>

<!--        <courseMeal-->
<!--          v-if="TabActiveIndex == 'course_content'"-->
<!--          id="7"-->
<!--        ></courseMeal>-->
<!--        <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />-->
		    <!-- <course-detail v-if="TabActiveIndex == 'course_content'"/> -->
			<course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />
      </div>
    </div>
    <leetDialog></leetDialog>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "../../utils/courseMix";
import courseDetail from "../../components/course/courseDetail";
import Menus from "../../components/course/menu";
import addBanner from "./components/addBanner";
import freeSystem from "./components/freeSystem";
import presalesConsultation from "./components/presalesConsultation";
import polyVideo from "./components/polyVideo";
import courseDescription from "./components/courseDescription";
import courseMeal from "./components/courseMeal";
import pageSelect from "./components/pageSelect";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help.vue";
import { getCountDownClass } from "@/service/index";
import UML from "@/components/course/coursesComponents/UML";
import OOD from "@/components/course/coursesComponents/OOD";
import ObjectOriented from "@/components/course/coursesComponents/ObjectOriented";
import UMLDetail from "@/components/course/coursesComponents/UMLDetail";
import { getCourseBanner } from "@/service";


export default {
  components: {
    leetDialog,
    Menus,
    courseDetail,
    addBanner,
    freeSystem,
    polyVideo,
    courseDescription,
    presalesConsultation,
    courseMeal,
    pageSelect,
    pageStickySelect,
    helpClass,
    UML,
    OOD,
    ObjectOriented,
    UMLDetail,
  },
  mixins: [courseMix],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      videoShow: false,
      bantxt: "北美CS留学求职-全流程解析攻略",
      dr: 11,
      br: 11,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/12-CsJob/banner.jpg"),
	  img2: require("../../assets/images/beimei/ad-banner-2.jpg"),
      form: {
        region: "v1",
      },
      activeNames: ["1"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        // { name: "班课套餐", index: "1", tag: "course_meal" },
        { name: "班课大纲", index: "1", tag: "course_content" },
        // { name: "班课模式", index: "2", tag: "course_mode" },
        // {name: "用户评价", index: "1", tag: "course_comment"},
        // { name: "售前咨询", index: "2", tag: "course_question" },
      ],
      currentIndex: "0",
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
      countDownText: "",
      countDownTimeStamp: -1,
      countDays: 0,
      countHours: 0,
      countMinutes: 0,
      countSeconds: 0,
      qrcode: {},
    };
  },
  created() {
    // this.setEndTime(10);
    console.log(this.startTime);
    this.getCountDownClass();
  },
  mounted() {
    window.scrollTo(0, 0);
    getCourseBanner(2).then((res) => {
      if (res.success) {
        this.qrcode = res.result[0];
      }
    });
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
        case "面向对象设计班":
          this.$router.push("/course/8");
          break;
        case "北美CS留学求职-全流程解析攻略":
          this.$router.push("/course/11");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    echange(val) {
      this.bzid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    timeCountDown(endDateStr) {
      if (endDateStr == -1) {
        return;
      }
      //结束时间
      var endDate = new Date(endDateStr);
      //当前时间
      var nowDate = new Date();
      //相差的总秒数
      if (endDate < nowDate) {
        window.location.reload();
      }
      var totalSeconds = parseInt((endDate - nowDate) / 1000);
      //天数
      var days = Math.floor(totalSeconds / (60 * 60 * 24));

      //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24);

      //小时数
      var hours = Math.floor(modulo / (60 * 60));

      modulo = modulo % (60 * 60);
      //分钟
      var minutes = Math.floor(modulo / 60);
      //秒

      var seconds = modulo % 60;

      this.countDays = days;
      this.countHours = hours;
      this.countMinutes = minutes;
      this.countSeconds = seconds >= 10 ? seconds : `0${seconds}`;

      setTimeout(() => {
        this.timeCountDown(endDateStr);
      }, 1000);
    },
    getCountDownClass() {
      getCountDownClass(7).then((res) => {
        if (res.success) {
          this.countDownTimeStamp = res.result;
          this.timeCountDown(this.countDownTimeStamp);
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/12-CsJob.css");
</style>
